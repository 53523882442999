import { 
    Client, 
    Databases, 
    Functions, 
    Query,
    Models, 
    ExecutionMethod
} from 'appwrite';

import { 
    APPWRITE_ENDPOINT, 
    PROJECT_ID, 
    APPWRITE_GET_UNVERIFIED_USERS_FUNCTION_ID, 
    APPWRITE_VERIFY_USER_FUNCTION_ID, 
    APPWRITE_DELETE_USER_FUNCTION_ID, 
    APPWRITE_DATABASE_ID, 
    ACCOUNT_VERIFICATION_DATA_TABLE_ID, 
    CREATE_OPERATOR_FUNCTION_ID, 
    GET_OPERATORS_FUNCTION_ID,
    UPDATE_OPERATOR_FUNCTION_ID,
    GET_USER_DATA_FUNCTION_ID,
    GENERAL_MAIL_SENDER_FUNCTION_ID
} from '../../constants/AppwriteConstants';

import User from '../../models/User';
import OperatorFormObj from '../../models/OperatorFormObj';
import { Address } from '../../models/Address';

async function getUsers(emailVerification: boolean) {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    try {
        const response = await functions.createExecution(
            APPWRITE_GET_UNVERIFIED_USERS_FUNCTION_ID,
            JSON.stringify({
                emailVerification: emailVerification
            }),
            false,
            '/',
            ExecutionMethod.POST
        );

        return JSON.parse(response.responseBody);
        
    } catch(error) {
        console.log(`Error while trying to get unverified users: ${error}`);
        return {
            message: `Internal server error`,
            users: []
        };
    }
}

async function getUsersData(ids: string[]) {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const databases = new Databases(client);

    try {
        const response = await databases.listDocuments(
            APPWRITE_DATABASE_ID,
            ACCOUNT_VERIFICATION_DATA_TABLE_ID,
            [Query.equal(`userID`, ids)]
        );
        return response.documents;
    } catch(error) {
        console.log(`Error while trying to get unverified accounts data: ${error}`);
        return [];
    }
}

async function verifyUser(userID: string) {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    try {
        const response = await functions.createExecution(
            APPWRITE_VERIFY_USER_FUNCTION_ID,
            JSON.stringify({ id: userID })
        );

        return response;
    } catch(error) {
        console.log(`Error while trying to verify user: ${error}`);
        return error;
    }
}

async function mailClient(email: string): Promise<void> {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    try {
        await functions.createExecution(
            GENERAL_MAIL_SENDER_FUNCTION_ID,
            JSON.stringify({
                receiver: email,
                content: `Contul a fost aprobat! Puteți intra în B2B cu credențialele furnizate.`,
                subject: `Cont aprobat`
            }),
            true,
            '/',
            ExecutionMethod.POST
        )
    } catch(error) {
        console.error(`There was an error while executing the general mail sender function: ${error}`);
    }
}

async function deleteUser(userID: string) {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    try {
        const response = await functions.createExecution(
            APPWRITE_DELETE_USER_FUNCTION_ID,
            JSON.stringify({ userID: userID })
        );

        return response;
    } catch(error) {
        console.log(`Error while trying to delete user: ${error}`);
        return error;
    }
}

async function deleteUserData(userID: string) {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const databases = new Databases(client);

    try {
        const getDocumentsResponse = await databases.listDocuments(
            APPWRITE_DATABASE_ID, 
            ACCOUNT_VERIFICATION_DATA_TABLE_ID, 
            [Query.equal(`userID`, userID)]
        );
        
        if(getDocumentsResponse.total > 0) {
            await databases.deleteDocument(APPWRITE_DATABASE_ID, ACCOUNT_VERIFICATION_DATA_TABLE_ID, getDocumentsResponse.documents[0].$id);
        }
        return { code: 200 }
    } catch(error: any) {
        console.log(`Error in UserService::deleteUserData - unable to delete account data: ${error}`);
        return {
            code: error.code,
            message: error.type
        }
    }
}

async function createOperator(operatorFormData: OperatorFormObj): Promise<{ status: string, message: string }> {
    const body = {
        user: {
            name: operatorFormData.name,
            email: operatorFormData.email,
            phone: `+4${operatorFormData.phone}`,
            password: operatorFormData.password
        }
    };
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    try {
        const response: Models.Execution = await functions.createExecution(
            CREATE_OPERATOR_FUNCTION_ID,
            JSON.stringify(body)
        );
        const responseBody: { message: string, error: any } = JSON.parse(response.responseBody);
       
        return {
            status: response.status,
            message: response.status === `completed` ? responseBody.message : responseBody.error.type
        };
    } catch(error: any) {
        console.error(`Error in UserService::createOperator - failed to create operator: ${error}`);
        return {
            status: `failed`,
            message: `Internal server error.`
        }
    }
}

async function getOperators(): Promise<User[]> {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    try {
        const response: Models.Execution = await functions.createExecution(
            GET_OPERATORS_FUNCTION_ID,
            '',
            false,
            '/', 
            ExecutionMethod.GET
        );
        const responseBody = JSON.parse(response.responseBody);
        const operators: User[] = responseBody.operators;

        return operators;
    } catch(error) {
        console.error(`Error in UserService::getOperators - unable to fetch operators: ${error}`);
        return [];
    }
}

async function updateUser(id: string, email: string | null, phone: string | null, tin: string | null, address: Address | null, additionalEmails: string[] | null, isPaused: boolean | null): Promise<{ status: string; message: string }> {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);
    const databases = new Databases(client);

    const body = {
        user: {
            id: id,
            email: email,
        }
    };

    try {
        const response: Models.Execution = await functions.createExecution(
            UPDATE_OPERATOR_FUNCTION_ID,
            JSON.stringify(body),
            false,
            '/',
            ExecutionMethod.POST
        );
        const responseBody: { message: string } = JSON.parse(response.responseBody);

        const listDocumentsResponse: Models.DocumentList<Models.Document> = await databases.listDocuments(
            APPWRITE_DATABASE_ID,
            ACCOUNT_VERIFICATION_DATA_TABLE_ID,
            [Query.equal(`userID`, id)]
        );

        if(listDocumentsResponse.total > 0) {
            const foundUser: Models.Document = listDocumentsResponse.documents[0];

            await databases.updateDocument(
                APPWRITE_DATABASE_ID,
                ACCOUNT_VERIFICATION_DATA_TABLE_ID,
                foundUser.$id,
                {
                    userID: id,
                    company_phone_number: phone || foundUser.company_phone_number,
                    company_tin: tin || foundUser.tin,
                    company_address: address !== null ? JSON.stringify(address) : foundUser.company_address,
                    additional_emails: additionalEmails || foundUser.additional_emails,
                    isPaused: isPaused !== null ? isPaused : foundUser.isPaused 
                }
            )
        }

        return {
            status: response.status,
            message: responseBody.message
        };
    } catch(error) {
        console.error(`Error in USerService::upddateOperator - failed to update operator: ${error}`);
        return {
            status: `failed`,
            message: `Server error`
        };
    }
}

async function getUserCharaData(username: string, id: string): Promise<{ status: string; user: any }> {
    const client = new Client().setEndpoint(APPWRITE_ENDPOINT).setProject(PROJECT_ID);
    const functions = new Functions(client);

    const body = {
        user: {
            username: username,
            id: id
        }
    };

    try {
        const response: Models.Execution = await functions.createExecution(
            GET_USER_DATA_FUNCTION_ID,
            JSON.stringify(body),
            false,
            '/',
            ExecutionMethod.POST
        );

        return {
            status: response.status,
            user: JSON.parse(response.responseBody)[`user`]
        };
    } catch(error) {
        console.error(`Error while trying to fetch user Chara data: ${error}`);
        return {
            status: `failed`,
            user: {
                tier: 'N / A',
                discountCategoryID: 0
            }
        };
    }
}

export const UserService = {
    getUsers,
    getUsersData,
    verifyUser,
    deleteUser,
    deleteUserData,
    createOperator,
    getOperators,
    updateUser,
    getUserCharaData,
    mailClient
}